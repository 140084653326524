/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}

.ProductActions {
    line-height: 16px;

    &-Notify_me_Parent {
        .Field-Wrapper {
            width : auto;
        } 

        align-items: center;
        display: flex;
        justify-content: space-between;
        padding-block: 24px;
        border-bottom: 1px solid #CBCBCB79;
        column-gap: 15px;
    }

    @include mobile() {
        padding: 16px;
    }

    &-Attributes {
        padding: 0;
        padding-block: 10px;
    }

    &-characteristics {
        border-bottom: 1px solid #CBCBCB79;

        @include mobile() {
            border-bottom: none;
        }
    }

    &-Notify_me_out_of_stock {
        font-size: 14px;
        color: var(--primary-base-color);
        font-weight: bold;
        display: grid;
        place-content: center;
    }

    &-Brand {
        font-weight: bold;
        opacity: .48;
        font-size: 12px;
        line-height: 16px;
    }

    &-Title {
        margin-block-start: 14px;
        font-size: 20px;
        line-height: 25px;
        margin-block-end: 14px;

        @include mobile {
            margin-block-start: 12px;
            font-weight: 400;
            font-size: 14px;
            margin-block-end: 0px;
        }
    }

    &-ShortDescription {
        div {
            font-size: 14px;
        }

        @include description-tags {
            line-height: 20px;
            color: #707070;

            p {
                margin-block-end: 0px;
            }

            @include mobile() {
                font-size: 12px;
            }
        }

        font-size: 14px;
        padding-block-start: 16px;
        padding-block-end: 16px;
        border-top: 1px solid #CBCBCB79;
        line-height: 20px;
        border-bottom: 1px solid #CBCBCB79;

        @include mobile() {
            border-bottom: 1px solid #CBCBCB79;
        }

        div[itemprop="description"] li::before {
            position: relative;
            margin-inline-end: 10px;
            inset-block-start: -1px;
        }
    }

    &-Stock {
        font-weight: bold;
        order: 0;

        @include desktop {
            margin-inline-end: 24px;
        }

        @include mobile {
            font-style: normal;
            font-size: 12px;
            line-height: 22px;
            margin-block-start: 8px;
        }
    }

    &-StockStatus {
        display: none;
    }

    &-Sku {
        order: 1;
        color: #707070;
        font-size: 14px;
        padding-block-end: 16px;
        margin-block-end: 12px;

        @include mobile {
            font-weight: normal;
            font-size: 12px;
            margin-block-start: 4px;
            text-align: end;
            margin-block-end: 0px;
        }

        & + .ProductActions-Sku {
            margin-inline-start: 1ex;
        }
    }

    .ExpandableContent {
        padding: 24px 0px;

        @include mobile() {
            padding: 0px;
        }

        &-Content {
            @include desktop {
                max-height: 0;
                opacity: 0;
            }

            &_isContentExpanded {
                @include desktop {
                    max-height: 100%;
                    opacity: 1;
                }
            }
        }
    }

    .Product-BrandnameLink {
        font-size: 14px;
    }

    &-Review {
        order: 2;
        font-weight: 700;
        cursor: pointer;
        inset-block-end: -2px;
        font-size: 14px;

        @include mobile {
            inset-block-end: 0;
        }

        &Text {
            color: var(--primary-base-color);

            &:hover {
                color: var(--primary-dark-color);
            }

            &_isNotSafariOrIos {
                @include mobile {
                    vertical-align: -webkit-baseline-middle;
                }
            }
        }
    }

    &-Section {
        &_type {
            &_sku {
                display: flex;

                @include mobile {
                    flex: 1;
                    align-items: flex-end;
                    flex-direction: column;
                    border-bottom: none;
                }

                .TextPlaceholder {
                    line-height: 30px;
                }
            }
        }
    }

    &-table-characteristics {
        margin-block-start: 21px;

        .additional-attributes {
            width: 100%;

            tbody {
                margin-block-start: 14px;

                tr {
                    line-height: 2;
                    display: grid;
                    grid-template-columns: 1fr 2fr;

                    .label {
                        font-size: 14px;
                        font-weight: 600;
                    }

                    .data {
                        font-size: 13px;
                        color: #525050;

                        a {
                            color: var(--primary-base-color);
                        }
                    }
                }

                tr:nth-child(odd) {
                    background-color: rgba(241,241,241,0.55);
                }
            }
        }
    }

    &-Schema {
        min-width: 150px;
        padding: 14px 0px ;

        @include desktop {
            border-bottom: 1px solid #f1f1
        }

        @include mobile() {
            padding: 0px;
            margin-block-start: 12px;
            margin-block-end: 12px;
        }
    }

    &-SchemaUrl {
        display: none;
    }

    &-PriceWrapper {
        @include desktop {
            display: flex;
            column-gap: 30px;
            align-items: center;
        }
    }

    &-ActionButtons {
        display: flex;
    }

    &-ActionsWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-block-end: 12px;

        &:nth-child(2) {
            @include mobile {
                align-items: start;
                border-bottom: 1px solid #CBCBCB79;
                border-top: 1px solid #CBCBCB79;
                padding: 10px 0px;
            }
        }

        &:nth-child(3) {
            @include mobile {
                margin-block-end: 22px;
            }
        }

        @include desktop {
            margin-block: 18px;
        }

        .ProductCompareButton {
            margin-inline-start: auto;
        }

        &_isWithoutPriceTotal .ProductActions-Section_type_sku {
            @include mobile {
                float: end;
            }
        }

        .Field-wrapper {
            &_type_number {
                width: 55%;
            }
        }
    }

    &-Notify_me {
        margin-block-start: 14px;
        padding-block-end: 14px;
        border-bottom: 1px solid #CBCBCB79 ;

        span {
            font-size: 16px;
            color: red;
        }
    }

    .NotifyStock {
        background-color: #fff;
        border-color: #BB8376;
        width: 100%;

        @include mobile() {
            width: 60%;
        }

        span {
            color: #BB8376;
            text-transform: capitalize;
        }

        &:not([disabled]):hover {
            background-color: #BB8376;
            border-color: #BB8376;

            span {
                color: #fff;
            }
        }
    }

    &-BrandnameText {
        color: var(--primary-base-color);
        cursor: pointer;
    }

    &-AddToCartFixed {
        position: fixed;
        inset-block-end: 0;
        z-index: 5;
        background-color: var(--color-white);
        width: 100%;
        display: flex;
        padding: 12px 16px;
        border-block-start: 1px solid var(--primary-divider-color);
        inset-inline-start: 0;

        @include mobile {
            justify-content: space-between;
        }

        .ProductWishlistButton {
            margin-inline: 18px 4px;
            margin-block: auto;
        }

        .Field {
            &-Wrapper {
                &_type {
                    &_number {
                        @include mobile {
                            display: flex;
                        }
                    }
                }
            }
        }

        .hideOnScroll & {
            transform: translateY(0);
        }
    }

    &-AddToCartWrapper {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding-block: 24px;
        border-bottom: 1px solid #CBCBCB79;
        column-gap: 15px;

        .Field-Wrapper {
            &_type_number {
                width: unset;

                .Field-ErrorMessages {
                    width: 300px;
                    display: flex;
                    column-gap: 5px;
                }
            }
        }

        .ProductActions-BuyNow {
            width: 100%;
            text-transform: capitalize;
            padding: 0;

            &:hover {
                padding: 0;
            }
        }

        .ProductActions-AddToCart {
            margin-inline-end: 0px;
            width: 100%;

            @include ultra-narrow-desktop {
                order: 10;
            }
        }

        .ProductCompareButton,
        .ProductWishlistButton {
            margin-inline: 16px;
        }

        @include ultra-narrow-desktop {
            justify-content: space-between;
        }

        &_isPrerendered {
            @include mobile {
                inset-block-end: 0;
            }
        }

        .Field-ErrorMessages {
            position: absolute;
        }
    }

    &-Qty {
        &.Field {
            margin-block: 0;
            height: 48px;

            &_type_number [type="number"] {
                pointer-events: painted;
            }

            @include mobile {
                margin-inline-end: 12px;

                input {
                    min-width: 36px;

                    @include mobile() {
                        height: 20px;
                    }
                }
            }
        }

        button {
            &:not([disabled]) {
                cursor: pointer;
            }

            &:disabled {
                cursor: default;
            }

            &:active {
                border-color: var(--primary-dark-color);
            }

            @include hoverable {
                &:hover {
                    border-color: var(--primary-dark-color);
                }
            }
        }
    }

    &-AttributesWrapper {
        @include mobile {
            margin-block-end: 16px;
        }

        .ProductConfigurableAttributes {
            &-SelectedOptionLabelActive {
                margin-inline-start: 10px;
                color: var(--primary-base-color);
            }
        }

        &.animate {
            animation: shake 820ms .5ms cubic-bezier(.36, .07, .19, .97) both;
        }
    }

    &-GroupedItems {
        margin-block-start: 24px;
    }

    &-Reviews {
        margin-block-end: 10px;
        display: flex;

        @include mobile {
            margin-block-end: 0;
        }

        .ProductReviewRating {
            margin-inline-end: 24px;
        }
    }

    .ProductConfigurableAttributes-Expandable {
        margin-block-start: 24px;
    }

    .ExpandableContent:first-of-type {
        border-block-start: 0;
    }

    &-AddToCartWrapper_outofstock {
        display: flex;
        margin-block-start: 16px;
        padding-block-end: 16px;
        border-bottom: 1px solid #CBCBCB79;
    }

    &-AddToCartWrapper_outofstock_button {
        display: flex;
        align-items: center;

        button {
            width: 208px;
            height: 40px;
            border: 1px solid #BA8377;
            color: #BA8377;
            font-weight: bold;

            &:hover {
                color: white;
                background-color: #BB8376;
                cursor: pointer;
            }
        }
    }
}
