/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.Product {
    @include mobile {    
        &-AddToCartParent {
            width: 100%;
        }
    }

    &-BrandnameText {
        font-size: 12px;
        line-height: 18px;
        color: #515150;
    }

    &-Wrapper {
        min-height: 492px;
    }

    &-MultiSelect {
        min-height: 285px;
    }
}

.ProductCard {
    &-ProductCardPrice {
        @include mobile {
            display: flex;
            flex-direction: column;
            width: 100%;
            justify-content: center;
            align-items: center;
        }
    }

    &-LoginLink {
        @include mobile {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .ConfigPopup {
        .popup {
            @include mobile {
                max-width: 350px;
            }

            .Field_type_number {
                button {
                    height: 24px;
                    width: 24px;
                    cursor: pointer;
                }
            }

            .Product-BrandnameText {
                padding: 12px 16px 4px 16px;
            }

            .Product {
                &-PackQuanity {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    color: #000000;
                    font-size: 12px;
                    font-weight: 600;
                }

                &-PackWrapper {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    color: #000000;
                    padding: 0px 16px 4px 16px;
                    margin-inline-end: 10%;

                    @include mobile {
                        margin-inline-end: 5%;
                    }
                }

                &-PackSize {
                    color: #000000;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 20px;
                }

                &-TotalPriceValue {
                    color: #0F0F0C;
                    font-size: 18px;
                    line-height: 22px;
                    font-weight: 700;
                }
                
                &-totalPrice {
                    display: flex;
                    justify-content: space-between;
                    padding: 14px 16px 9px 16px;
                }
            
                &-totalPriceText {
                    color: #3A3A36;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 20px;
                    margin-block-end: 0px;
                }
            
                &-ListProductValuesWrapper {
                    border-top: 1px solid #F0EFEF;
                    border-bottom: 1px solid #F0EFEF;
                    padding: 8px 16px 8px 16px;
                }
            
                &-OutOfStock {
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 18px;
                    color: #F06F6F;
                }
            
                &-SalableQty {
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 18px;
                    color: #73B486;
                }
            
                &-SeedsQuanity {
                    background: #F9F9F9;
                    border-radius: 4px;
                }
            
                &-SeedsDisableText {
                    opacity: 0.5;
                    font-size: 16px;
                    color: #AEAEAE;
                }
                
                &-ListProductValues {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
            
                &-SeedsNumber {
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 600;
                    color: #0F0F0C;
                }
            
                &-SeedsDisable {
                    opacity: 0.5;
                    cursor: not-allowed;
                }

                &-SeedsDisablePrice {
                    opacity: 0.5;
                    font-size: 12px;
                    color: #AEAEAE;
                }
            
                &-SeedsPrice {
                    font-size: 12px;
                    line-height: 20px;
                    font-weight: 400;
                    color: #515150;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }            
            }

            .ProductCard {
                &-MultiselectContainerWrapper {
                    max-height: 285px;
                    overflow: auto;
                }
    
                &-MultiselectContainerWrapper::-webkit-scrollbar {
                    width: 6px;
                    scrollbar-width: thin
                }
    
                &-MultiselectContainerWrapper::-webkit-scrollbar-track {
                    background-color: #F0EFEF;
                }
                
                &-MultiselectContainerWrapper::-webkit-scrollbar-thumb {
                    background-color: #E84620; 
                    border-radius: 20px; 
                    border: 1px solid #E84620; 
                    height: 6px;            
                }

                &-AddToCart {
                    width: -webkit-fill-available;
                    margin: 0px 16px 16px 16px;
                    height: 48px;
                }

                &-Title {
                    font-size: 20px;
                    line-height: 25px;
                    font-weight: 700;
                    padding-block-start: 4px;
                    padding: 0px 16px 15px 16px;
                }
            
                &-Qty {
                    width: auto;

                    [type="number"] {
                        background-color: #F9F9F9!important;/* stylelint-disable-line declaration-no-important */
                    }
                }
            }
        }
    }

    &-breederbrand {
        font-size: 14px;
        color: var(--primary-base-color);
        margin-block-end: 12px;
        font-weight: bold;
    }
}

.ProductPrice {    
    &-HighPrices {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        text-align: start;
        padding-inline-start: 10px;
        color: #C7C6C6;
    }

    &-discounts {
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
        text-align: start;
        background-color: #EED247;
        padding-inline: 8px;
        margin-inline-start: 8px;
        border-radius: 100px;
    }
}
