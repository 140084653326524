/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --product-card-picture-mask-color: #f5f5f5;
    --product-card-color-border-color: #efefef;
    --product-card-text-border-color: #979797;
    --product-card-picture-label-background: rgba(255, 255, 255, 0.7);
    --product-card-reviews-background: rgba(243, 243, 243, 0.55);
    --product-card-wishlist-button-background: rgba(243, 243, 243, 0.78);
    --product-card-wishlist-button-color: #d8d5d5;
    --product-card-compare-button-background: var(--product-card-wishlist-button-background);
    --product-card-compare-button-color: var(--product-card-wishlist-button-color);
    --product-card-name-max-rows: 2;
    --product-card-brand-line-height: 1.2em;
    --product-card-color-size: 32px;
    --product-card-background: var(--color-white);
    --option-margin-block: 0 12px;
    --option-margin-inline: 0 12px;
}

@keyframes slideUp {
    from {
        transform: translate3d(0, 100%, 0);
        visibility: visible;
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

.ProductCard {
    height: fit-content;
    min-width: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border: 1px solid #DFDFDF;
    inset-block-end: 0;
    box-shadow: 0px 1px 6px #0000000A;
    border-radius: 8px;

    &-totalPriceText {
        padding: 14px 24px 0px 24px;
    }

    &-TotalPriceValueList {
        padding: 0px 24px 14px 24px;
    }

    &-totalPriceWrapperList {
        display: flex;
        flex-direction: column;
    }


    .Field_type_number {
        border: none;
    }

    &-VisibleOnHover {
        opacity: 0;
        transform: translateY(100%);
    }

    &-FooterWrapper {
        display: none;
    }

    &-BrandLogo {
        position: absolute;
        inset-block-end: 15px;
        z-index: 4;
        border-radius: 50%;
        overflow: hidden;
        padding: 2px;
        width: 48px;
        height: 48px;
        transition: inset-block-end 0.5s;
        inset-inline-end: 20px;

        &.ProductCard-BrandLogo_layout_grid {
            img {
                width: 100%;
            }
        }

        .Image_imageStatus_image_loaded.Image_hasSrc {
            background: white;

            img {
                width: 80%;
                object-fit: contain;
                margin: auto;
                inset-inline-end: 0;
            }
        }

        .Image_imageStatus_image_not_found {
            display: none;
        }
    }

    &:hover {
        @include desktop {
            .ProductCard-VisibleOnHover {
                background: var(--product-card-background);
                border-top: 1px solid #f4f4f4;
                width: 100%;
                height: fit-content;
                opacity: 1;
                z-index: 3;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                flex-grow: 1;
                padding-block-start: 15px;
                padding-block-end: 15px;
                padding-inline: 15px;
                position: absolute;
                inset-block-end: 0;
                transition: height .9s;
                transform: translateY(0%);
            }

            .ProductCard-VisibleOnHover_withVariants {
                background: var(--product-card-background);
                border-top: 1px solid #f4f4f4;
                width: 100%;
                height: 39%;
                opacity: 1;
                z-index: 3;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                flex-grow: 1;
                padding-block: 10px;
                padding-inline: 15px;
                position: absolute;
                inset-block-end: 0;
            }

            .ProductCard-VisibleOnHover_noFeatures {
                background: var(--product-card-background);
                border-top: 1px solid #f4f4f4;
                width: 100%;
                height: 18%;
                opacity: 1;
                z-index: 3;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                flex-grow: 1;
                padding-block: 10px;
                padding-inline: 15px;
                position: absolute;
                inset-block-end: 0;
            }

            .ProductCard-FooterWrapper {
                display: flex;
                flex-direction: column;
                height: 100%;
                justify-content: space-between;

                .Field-Wrapper {
                    &_type_number {
                        width: 50%;
                    }
                }

                .ProductCard-AddToCart {
                    span {
                        text-transform: initial;
                    }
                }

                .ProductCard-ProductFeaturesLists {
                    li {
                        display: flex;
                        align-items: center;
                        column-gap: 4px;

                        span {
                            height: 16px;
                        }

                        &::before {
                            display: none;
                        }
                    }
                }

                .ProductActions-AttributesWrapper {
                    .ProductConfigurableAttributes {
                        .Field-Wrapper {
                            &_type_select {
                                .ProductConfigurableAttributeDropdown {
                                    width: auto;

                                    .FieldSelect {
                                        &-Select {
                                            min-height: 20px;
                                            height: 30px;
                                            padding: 5px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .ProductCard-BrandLogo_layout_grid {
                inset-block-end: 18px;
            }
        }
    }

    &::before {
        content: none;
    }

    &:last-child {
        margin-block-end: 7px;

        @include mobile {
            margin-block-end: 8px;
        }
    }

    .ProductConfigurableAttributes {
        &-Title {
            display: block;
            margin-block-start: 12px;
        }

        &-SelectedOptionLabelActive {
            color: var(--primary-base-color);
            border: 1px solid var(--primary-base-color);
            margin-inline-start: 10px;
            padding: 3px;
            font-size: 12px;
        }
    }

    &-Attributes {
        padding: 0;

        .ProductConfigurableAttributes {
            &-Title {
                display: block;
                margin-block-start: 5px;
                margin-block-end: 0;
                font-size: 12px;
                color: #707070;
                font-weight: 500;
            }

            &-SwatchList {
                flex-wrap: nowrap;
                overflow-y: hidden;
                overflow-x: auto;

                &::-webkit-scrollbar {
                    width: 10px; /* for vertical scrollbars */
                    height: 5px; /* for horizontal scrollbars */
                }

                &::-webkit-scrollbar-track {
                    background: rgba(0, 0, 0, 0.2);
                    border-radius: 10px;
                }

                &::-webkit-scrollbar-thumb {
                    background: rgba(0, 0, 0, 0.1);
                }

                .ProductAttributeValue {
                    &-String {
                        span {
                            white-space: nowrap;
                        }

                        &_isSelected {
                            margin-block-end: 0px;
                        }
                    }

                    &-qty {
                        display: none;

                        &_isSelected {
                            display: block;
                            color: var(--primary-base-color);
                            font-size: 12px;
                            width: 7rem;
                        }
                    }
                }

                &_isUnselected {
                    .ProductAttributeValue {
                        &_isNotAvailable {
                            .ProductAttributeValue-String {
                                border-color: var(--primary-base-color);

                                .Outofstock-label {
                                    display: none;
                                }
                            }
                        }
                    }
                }

                &_isUnselected_null {
                    .ProductAttributeValue {
                        &_isNotAvailable {
                            .ProductAttributeValue-String {
                                border-color: var(--primary-base-color);

                                .Outofstock-label {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &-ConfigurationNotice {
        opacity: 0.3;
        font-weight: normal;
        margin-block-start: -10px;
        margin-block-end: 10px;
    }

    &-Content {
        background: var(--product-card-background);
        display: flex;
        flex-direction: column;
        padding-block: 12px;
        border-radius: 0px 0px 8px 8px;

        @include mobile {
            padding-block-start: 8px;
            padding-block-end: 4px;
            // height: 193px;
            justify-content: space-between;

            .ViewProduct_layout_grid {
                background-color: #fff;
                height: 36px;

                span {
                    font-size: 1.2rem;
                    text-transform: capitalize;
                    color: var(--primary-base-color);
                }

                &:not([disabled]):hover {
                    height: 36px;
                    background-color: var(--primary-base-color);
                    border-color: var(--primary-base-color);
                    color: #fff;

                    span {
                        color: #fff;
                    }
                }
            }
        }

        @include desktop {
            .ViewProduct_layout_grid {
                background-color: #fff;
                height: 36px;

                span {
                    font-size: 1.2rem;
                    text-transform: capitalize;
                    color: var(--primary-base-color);
                }

                &:not([disabled]):hover {
                    height: 36px;
                    background-color: var(--primary-base-color);
                    border-color: var(--primary-base-color);

                    span {
                        color: #fff;
                    }
                }
            }
        }

        &_layout_list {
            margin-inline-start: 0px;
            display: block;
            width: 100%;
            padding-block: 0px;
            // padding-inline-end: 25px;

            .Product {
                &-BrandnameLink {
                    width: 100%;
                }
            }
            
            .HeartIcon {
                stroke: #000!important;/* stylelint-disable-line declaration-no-important */
            }

            .ProductCard {
                &-ProductListSku {
                    display: flex;
                    align-items: center;
                    padding-block: 16px;
                }
                
                &-ActionWrapper {
                    align-items: center;
                    display: flex;

                    button {
                        margin-inline-end: 10px;
                    }
                }

                &-Content {
                    padding-inline: 0 15px;
                }

                &-ContentMainBlock {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    min-width: 274px;
                }

                &-Name {
                    width: 80%;
                    height: 100%;
                }

                &-MainInfo {
                    display: flex;
                    flex-direction: column;
                    // padding: 28px 20px;

                    .ProductCard-CategoryName {
                        font-size: 16px;
                    }

                    .ProductCard-Name {
                        font-size: 20px;
                        line-height: 24px;
                        font-weight: 700;
                        margin-block-start: 4px;
                        margin-block-end: 4px;
                    }

                    .ProductCard-SkuName {
                        font-size: 14px;
                        padding-inline-end: 16px;
                        margin-block-end: 0px;
                    }

                    .mpproductlabel-label {
                        /* stylelint-disable declaration-no-important */
                        inset-block-start: unset;
                        inset-inline-start: unset;
                    }
                }
                
                &-MainInfo:has(.mpproductlabel-label) {
                    padding: 28px 20px;
                }

                &-AttributeWrapper {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                &-ProductActions {
                    flex-direction: row;
                    position: static;

                    > div {
                        margin-block-end: 0;
                    }

                    .ProductCompareButton-Button {
                        height: 38px;
                        width: 38px;
                    }
                }

                &-OutOfStock {
                    p {
                        margin-inline-end: 10px;
                    }
                }

                &-PriceWrapper {
                    padding: 14px 0px 0px 24px!important;
                    padding-inline-end: 0px!important;
                    flex-grow: unset;
                    margin-block-end: 0px;

                    .ProductCard-Price {                        
                        .ProductPrice {
                            &-PriceBadge {
                                font-size: 14px;
                                text-transform: lowercase;
                            }

                            &-PriceValue {
                                font-size: 18px;

                                @include mobile {
                                    font-size: 14px;
                                }
                            }

                            &-DiscountPercent {
                                font-size: 16px;

                                @include mobile {
                                    font-size: 12px;
                                }
                            }

                            &-HighPrice {
                                @include mobile {
                                    font-size: 12px;
                                    margin-inline: 4px;
                                }
                            }
                        }
                    }
                }

                &-WishListButton {
                    height: var(--wishlist-heart-size);
                    width: var(--wishlist-heart-size);
                }
            }
        }
        
        &_layout_list:has(.mpproductlabel-label) {
            padding-inline: unset; 
        }
    }

    &-RegisterLink {
        color: #0F0F0C;
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
        text-decoration: underline;
        padding-block-start: 8px;
    }


    &-RegisterLink:hover {
        color: #0F0F0C;
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
        text-decoration: underline;
        padding-block-start: 8px;
    }

    .ConfigPopup {
        .popup {
            .Product-BrandnameLink a {
                @include desktop {
                    font-weight: 600;
                    padding: 12px 16px 4px 16px;
                    color: #515150;
                    display: flex;
                }
            }

            .Product {
                &-BrandnameText {
                    font-weight: 600;
                }
            }

            .ProductCard {
                &-Title {
                    font-size: 20px;
                    line-height: 25px;
                    font-weight: 700;
                    color: #0F0F0C;
                    width: 90%;
                }
            }

            .NotifyStock {
                margin: 0px 16px 16px 16px;
                width: -webkit-fill-available;
            }

            .NotifyStock:not([disabled]):hover {
                width: -webkit-fill-available;
            }

            .Popup-CloseBtn {
                svg {
                    width: 18px;
                    height: 18px;
                }
            }
        }    
    }

    

    &-LoginLinkButton {
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        background-color: #E84620;
        border-radius: 8px;
        padding: 4px 16px 4px 16px;
        text-transform: uppercase;
        cursor: pointer;
        margin-inline-end: 16px;

        @include mobile {
            width: 100%;
            font-size: 12px;
            margin-inline-end: 0px;
            border-radius: 4px;
        }
    }

    &-HoverLoginLink {
        border: 1px solid var(--primary-base-color);
        text-align: center;
        padding-block: 10px;
        padding-inline: 10px;

        &:hover {
            background-color: var(--primary-base-color);
            color: #fff;
        }

        @include mobile {
            font-size: 12px;
        }
    }

    &_layout_grid {
        .ProductPrice {
            &-HighPrice {
                display: none;
            }

            &-DiscountPercent {
                display: none;
            }

            &-Price {
                display: flex;
                flex-direction: column;

                @include mobile {
                    flex-direction: row;
                }
            }

            &-PriceBadge {
                font-size: 12px!important;
                font-weight: 400;
                color: #3A3A36;
            }

            &-PriceValue {
                font-size: 18px!important;
                font-weight: 600;
                line-height: unset;
                margin-block-start: 0px;

                @include mobile {
                    font-size: 16px!important;
                }
            }
        }

        .ProductCard {
            &-PriceWrapper {
                align-self: baseline;
                padding: 0px;
            }

            &-ContentGrid {
                flex-direction: column;
                padding-inline: 16px;

                p {
                    @include mobile {
                        white-space: break-spaces;
                    }
                }
            }

            &-ProductFeaturesGrid {
                li {
                    margin-block-end: 0;
                    display: flex;
                    gap: 5px;
                    font-size: 13px;

                    span {
                        margin-block-start: 2px;
                    }

                    &::before {
                        display: none;
                    }
                }
            }

            &-Name {
                @include mobile {
                    margin-block-end: 8px;
                }
            }
                    
            &-AddToCart {
                width: auto;
                height: 32px;
                background: #E84620;
                border-radius: 8px;
                padding: 4px 16px 4px 16px;
                border: unset;
                // margin-block-end: 14px;
                margin-block-start: 5px;

                @include mobile {
                    width: 100%;
                    height: 32px;
                    background: #E84620;
                    border-radius: 4px;
                    padding: 4px 16px 4px 16px;
                    border: unset;
                    min-width: unset;
                    line-height: unset;
                    margin-block-end: 0px;
                }

                span {
                    font-size: 14px;
                    font-weight: 600;
                    color: #FFFFFF;
                    text-transform: uppercase;
                }
            } 

            &-AddToCart:hover {
                width: auto;
                height: 32px;
                background: #E84620;
                border-radius: 8px;
                padding: 4px 16px 4px 16px;
                border: unset;
                // margin-block-end: 14px;

                span {
                    font-size: 14px;
                    font-weight: 600;
                    color: #FFFFFF;
                    text-transform: uppercase;
                }

                @include mobile {
                    width: 100%;
                    margin-block-end: 0px;
                }
            }
        }
    }

    .NotifyStock {
        background-color: #fff;
        border-color: #0F0F0C;
        border-radius: 8px;
        padding: 4px 16px 4px 16px;
        height: 32px;
        width: auto;

        span {
            color: #0F0F0C;
            text-transform: uppercase;
            font-size: 14px;
            line-height: 24px;
            font-weight: 600;

            @include mobile {
                font-size: 12px;
            }
        }

        svg {
            margin-inline-end: 8px;
        }

        &:not([disabled]):hover {
            background-color: #fff;
            border-color: #0F0F0C;
            border-radius: 8px;
            padding: 4px 16px 4px 16px;
            height: 32px;
            width: auto;

            span {
                color: #0F0F0C;
            }
       
            svg {
                margin-inline-end: 8px;
            }
        }
    }

    &_layout_list {
        box-shadow: 1px 2px 10px #00000014;
        margin-block-end: 25px;
        padding: 16px 24px; 
        // margin: 16px 25px;

        .NotifyStock {
            margin: 14px 24px;
        }

        .ProductPrice {
            &-HighPrice {
                display: none;
            }

            &-DiscountPercent {
                display: none;
            }
        }

        .ProductCard-Qty [type="number"] {
            min-width: 32px!important;
        }

        .Field_type_number {
            width: 100%;

            button {
                border: none;
                width: 32px;
                height: 32px;
                cursor: pointer;

                svg {
                    height: 24px;
                    width: 24px;
                }
            }
        }

        .Product {
            &-OutOfStockList {
                color: #F06F6F;
                font-size: 12px;
                font-weight: 600;
                line-height: 18px;
            }

            &-DisableAvailableQtyReader {
                opacity: 0.5;
                background-color: #fff;
                color: #DFDFDF;
                pointer-events: none;
            }

            &-TotalPriceValue {
                color: #0F0F0C;
                font-size: 18px;
                font-weight: 600;
                line-height: 26px;
            }

            &-ListProductValuesWrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 16px 24px 18px 24px;
                border-top: 1px solid #F0EFEF;
                border-bottom: 1px solid #F0EFEF;
            }

            &-ListProductValues {
                display: flex;
                align-items: center;
            }

            &-SeedsNumber {
                font-size: 12px;
                font-weight: 600;
                line-height: 18px;
                color: #3A3A36;
            }

            &-SeedsPrice {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                color: #3A3A36;
            }
        }

        .ProductCard {
            &-LastBlockSimpleProducts {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            &-MultiselectParentWrapper {
                min-height: 304px;
                max-height: 305px;
                overflow-y: auto;
            }

            &-MultiselectParentWrapper::-webkit-scrollbar {
                width: 6px;
                scrollbar-width: thin
            }

            &-MultiselectParentWrapper::-webkit-scrollbar-track {
                background-color: #F0EFEF;
            }
            
            &-MultiselectParentWrapper::-webkit-scrollbar-thumb {
                background-color: #E84620; 
                border-radius: 20px; 
                border: 1px solid #E84620; 
                height: 6px;            
            }

            &-StockInfo {
                width: 100%;
            }

            &-ActionWrapper:has(.ProductCard-StockInfo) {
                border-top: none;
                height: 100%;
                padding-block-start: 0px;
            }

            &-ProductWrapperList:has(.ProductCard-Link .Image) {
                .ProductCard-ContentImage {
                    padding: 16px 0px 0px 16px;
                }

                .ProductCard-ContentName {
                    padding: 16px 16px 0px 16px!important;
                    width: 100%;
                }
            }

            &-ProductWrapperList:has(.ProductCard-Multiselect) {
                display: flex;

                .ProductCard-Content.ProductCard-Content_layout_list {
                    .ProductCard-Link .Image {
                        width: 115px;
                        height: 130px;
                        border-radius: 8px;
                    }

                    .ProductCard-Link .Image img {
                        width: 115px;
                        height: 130px;
                    }

                    .ProductCard-ContentName {
                        padding: 16px 16px 0px 24px;
                        width: 100%;
                    }

                    .ProductCard-ContentParent {
                        display: flex;
                        flex-direction: row-reverse;
                        justify-content: space-between;
                    }

                    .ProductCard-ContentGrid {
                        padding: 16px 24px 0px 24px;
                    }

                    .ProductCard-Content.ProductCard-Content_layout_list {
                        padding-block-end: 0px!important;
                    }

                    // .ProductCard-Name {
                    //     padding-inline: 24px;
                    // }

                    .ProductCard-ProductListSku {
                        padding-inline: 24px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }

                    .ProductCard-StockStatusHeading {
                        padding-inline: 24px;
                    }

                    .ProductCard-StockLabels {
                        padding-inline: 24px;
                        padding-block-end: 6px;
                    }
                }
            }

            &-LinkBlock-1:has(.ProductCard-Multiselect) {
                width: 60%;
            }

            &-LinkBlock-2:has(.ProductCard-MultiselectWrapper) {
                width: 40%;
                background-color: #F9F9F9;
            }

            &-DisableAvailableQty {
                opacity: 0.5;
                color: #DFDFDF;
                font-size: 18px;
                font-weight: 700;
                line-height: 24px;
            }

            &-StockAvailabileSalableQty {
                color: #0F0F0C;
                font-size: 18px;
                font-weight: 700;
                line-height: 24px;
            }

            &-StockAvailabileBrandLabel {
                color: #3A3A36;
                font-size: 12px;
                font-weight: 600;
                line-height: 18px;
                width: 84px;
                text-align: center;
            }

            &-MultiselectWrapper {
                color: #0F0F0C;
                background-color: #F0EFEF;
                font-size: 12px;
                line-height: 18px;
                font-weight: 600;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px 10px;
            }

            &-totalPriceText {
                font-size: 12px;
                line-height: 20px;
                font-weight: 400;
                color: #3A3A36;
                margin-block-end: 0px;
            }

            &-totalPrice {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                // padding: 14px 24px;
            }

            &-Qty {
                background: #FFFFFF;
                width: 100%!important;
            }

            &-ConfigurableOptions {
                background: #F9F9F9;
            }

            &-StockStatusHeading {
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                padding-block-end: 8px;
                padding-block-start: 16px;   
            }

            &-StockLabels {
                width: 100%;
                display: flex;
                gap: 24px;
                max-width: 584px;
                overflow-x: auto;
            } 

            &-StockLabels::-webkit-scrollbar {
                width: 6px;
                height: 6px;
            }

            &-StockLabels::-webkit-scrollbar-track {
                background-color: #F0EFEF;
            }
            
            &-StockLabels::-webkit-scrollbar-thumb {
                background-color: #E84620; 
                border-radius: 20px; 
                border: 1px solid #E84620; 
            }

            &-StockAvailabilityValue {
                width: 116px;
                display: flex;
                flex-direction: column;
                padding: 8px 16px 8px 16px;
                align-items: center;
                border: 1px solid #DFDFDF;
                border-radius: 8px;
            }

            .AddToCart {
                @include mobile {
                    min-width: none;
                }
            }

            &-ProductLoginText {
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
            }

            &-LoginLinkButton {
                margin-inline-end: 0px;
                margin-inline-start: 24px;
            }

            &-ProductRegisterWrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
            }

            &-LinkBlock-1 {
                display: flex;
                max-width: 100%;
                color: inherit;
            }

            &-LinkBlock-2 {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            &-BrandLogo_layout_list {
                position: absolute;
                inset-block-end: 30px;
                z-index: 4;
                border-radius: 50%;
                background-color: #fff;
                padding: 9px;
                width: 48px;
                height: 48px;
                transition: inset-block-end 0.5s;
                inset-inline-end: 34px;
            }

            &-ContentGrid {
                border-bottom: none;
                padding-inline: none!important;
                
                h4 {
                    padding-block-start: 10px;
                }

                a {
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 20px;
                    padding-block-end: 8px;
                }
            }

            &-ActionWrapper {
                display: flex;
                gap: 20px;
                padding-block-start: 16px;
                // padding: 20px;
                width: 100%;
                border-top: 1px solid #DFDFDF;

                .Field-Wrapper_type_number {
                    width: unset;
                    margin-inline-start: 44px;

                    .ProductCard-Qty {
                        width: 116px;
                        height: 40px;

                        [type="number"] {
                            min-width: 45px;
                            height: 38px;
                        }

                        button {
                            svg {
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                }

                .AddToCart_layout_list {
                    height: auto;
                    border-radius: 8px;
                    width: auto;
                    padding: 4px 24px 4px 24px;
                    background-color: #E84620;
                    border: none;
                    margin: 16px 24px;
                    float: inline-end;

                    span {
                        font-size: 14px;
                        line-height: 24px;
                        font-weight: 600;
                        color: #FFFFFF;
                        text-transform: uppercase;
                    }

                    &:not([disabled]):hover {
                        height: auto;
                        border-radius: 8px;
                        width: auto;
                        padding: 4px 24px 4px 24px;
                        background-color: #E84620;
                        border: none;

                        span {
                            font-size: 14px;
                            line-height: 24px;
                            font-weight: 600;
                            color: #FFFFFF;
                            text-transform: uppercase;
                        }
                    }
                }

                .NotifyStock {
                    margin-block-start: 15px;
                }
            }

            &-ProductFeatures {
                padding: 15px 0;
            }

            &-ProductFeaturesLists {
                display: flex;
                flex-direction: column;
                padding: 0 25px;
                justify-content: space-between;
                gap: 10px;

                li {
                    margin-block-end: 0;
                    display: flex;
                    align-items: center;
                    gap: 5px;

                    span {
                        margin-block-start: 2px;
                    }

                    &::before {
                        display: none;
                    }
                }
            }

            &-Attributes {
                .Field-Wrapper {
                    &_type_select {
                        .ProductConfigurableAttributeDropdown {
                            @include desktop {
                                width: 200px;
                            }
                        }
                    }
                }

                .ProductConfigurableAttributes {
                    &-Title {
                        font-size: 14px;
                        font-weight: 500;
                    }

                    &-SwatchList {
                        width: 400px;
                        flex-wrap: nowrap;
                        overflow-y: hidden;
                        overflow-x: auto;

                        &::-webkit-scrollbar {
                            width: 10px; /* for vertical scrollbars */
                            height: 10px; /* for horizontal scrollbars */
                        }

                        &::-webkit-scrollbar-track {
                            background: rgba(0, 0, 0, 0.2);
                            border-radius: 10px;
                        }

                        &::-webkit-scrollbar-thumb {
                            background: rgba(0, 0, 0, 0.1);
                        }

                        .ProductAttributeValue {
                            &-String {
                                width: 75px;
                                height: 36px;

                                span {
                                    font-size: 14px;
                                    white-space: nowrap;
                                }
                            }
                        }
                    }
                }
            }

            &-ProductsActionIcons {
                display: flex;
                position: absolute;
                inset: 0px 20px auto auto  ;
            }

            &-ProductActions {
                position: revert;
                display: flex;
                flex-direction: column;
                gap: 10px;

                .ProductWishlistButton {
                    width: 38px;
                    height: 38px;

                    .Button {
                        background: #fff;
                        border: none;
                        padding: 0px;
                        border-radius: 20px;
                        height: 38px;
                        width: 55px;
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-end;

                        svg {
                            width: 16px;
                            height: 16px;
                            stroke: #000;
                        }
                    }
                }

                .ProductButton {
                    &-ShareButton {
                        width: 38px;
                        height: 38px;
                        background: #fff;
                        border: 1px solid #ededed;
                        border-radius: 20px;
                        padding: 9px 8px 8px;
                        cursor: pointer;

                        svg {
                            width: 16px;
                            height: 16px;
                        }
                    }
                }

                .ProductCompareButton {
                    width: 38px;
                    height: 38px;

                    .Button {
                        background: #fff;
                        border: 1px solid #ededed;
                        padding: 8px;
                        border-radius: 20px;
                        height: 38px;
                        width: 38px;

                        svg {
                            width: 16px;
                            height: 16px;
                        }
                    }
                }
            }
        }
    }

    &_layout_list:has(.mpproductlabel-label) {
        padding: unset;
    }

    &-ProductCardLastRow {
        width: 100%;
        min-height: 44px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include mobile {
            flex-direction: column;
            min-height: 52px;
        }
    }

    &-Name,
    &-Brand {
        width: 100%;
        max-width: 100%;
    }

    &-Name {
        text-transform: capitalize;
        font-size: 18px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-weight: 700;
        margin-block-start: 4px;
        margin-block-end: 12px;
        line-height: 24px;
        height: 48px;

        @include mobile {
            font-size: 14px;
            height: 60px;
            line-height: 20px;
            font-weight: 600;
        }
    }

    &-SkuName {
        color: #3A3A36;
        font-size: 12px;
        margin-block-end: 10px;
        font-weight: 600;
        line-height: 18px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @include mobile {
            max-width: 150px;
        }
    }

    &-ContentStatus {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-inline: 16px;
        padding-block: 12px;
        border-bottom: 1px solid #DFDFDF;

        @include mobile {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-inline: 12px;
            padding-block: 8px;
        }

        p {
            margin-block-end: 0px;

            @include mobile {
                margin-block-end: 8px;
            }
        }
    }

    &-QuantityChanger {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    &-Qty {
        width: 96px;
        height: 32px;

        [type="number"] {
            background-color: #FFFFFF;
            min-width: 23px;
            height: 23px;
            font-size: 14px;
        }
    }

    &-Brand {
        font-weight: 400;
        margin-block-end: 4px;
        opacity: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        min-height: var(--product-card-brand-line-height);
        line-height: var(--product-card-brand-line-height);
        font-size: 13px;
        color: var(--primary-base-color);

        @include desktop {
            line-height: 16px;
        }
    }

    &-BrandAttributeValue {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    }

    &-Picture {
        // padding-block-end: 93%;
        height: 144px;
        border-radius: 8px 8px 0px 0px;

        @include mobile {
            height: 112px;
        }

        .Image-Image {
            object-fit: contain;
            width: inherit;
            border-radius: 8px 8px 0px 0px;
        }
    }

    &-CategoryName {
        color: var(--primary-base-color);
        text-decoration: underline;
        text-transform: capitalize;
        font-size: 12px;
        margin-block-end: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @include mobile {
            text-decoration: none;
        }
    }

    &-PictureMissing {
        position: absolute;
        text-align: center;
        width: 100%;
        height: 20px;
        inset-block-end: 0;
        margin: auto;
        inset-block-start: 0;
    }

    &-LinkInnerWrapper {
        width: 100%;
        height: 100%;
    }

    &-FigureReview {
        @include desktop {
            padding-block: 0px 0;
            padding-inline: 0px;
        }
    }

    .HeartIcon {
        stroke: #FFFFFF;
    }

    &-Reviews {
        --product-rating-size: 16px;
        --product-rating-background: #ffff;

        width: 100%;
        border-radius: 10px;
        letter-spacing: normal;

        @include mobile {
            --product-rating-size: 14px;

            width: 100%;
            border-radius: 0;
            height: 18px;
        }

        @include desktop {
            height: 25px;
        }
    }

    &-ConfigurableOptions {
        flex-wrap: wrap;
        min-height: calc(var(--product-card-color-size) + 5px);
    }

    &-Color,
    &-Image {
        width: var(--product-card-color-size);
        height: var(--product-card-color-size);
        border-radius: 50%;
        overflow: hidden;
        margin-block-start: 5px;
        margin-inline-end: 7px;
        border: 1px solid var(--product-card-color-border-color);
    }

    &-String {
        border: 1px solid var(--product-card-text-border-color);
        color: var(--product-card-text-border-color);
        display: inline-block;
        text-align: center;
        padding: 0 6px;
        margin: 0;
        margin-block-start: 5px;
        margin-inline-end: 7px;
        line-height: 15px;
        min-width: 20px;
    }

    &-PriceWrapper {
        padding: 14px 0px 0px 24px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    &-Price {
        width: 100%;

        .ProductPrice {
            &-PriceValue {
                font-size: 14px;

                @include mobile {
                    font-size: 10px;
                }
            }

            &-DiscountPercent {
                @include mobile {
                    font-size: 10px;
                }
            }

            &-HighPrice {
                @include mobile {
                    font-size: 10px;
                    margin-inline: 4px;
                }
            }
        }

        @include desktop {
            font-size: 24px;
        }
    }

    &-PriceBadge {
        color: var(--secondary-dark-color);
        font-size: 10px;
        margin-block-end: 0;
    }

    .ProductPrice-PriceBadge {
        font-size: 14px;
    }

    .ProductPrice-BundleTo {
        @include mobile {
            margin-block-start: 6px;
        }
    }

    &-Figure {
        flex-grow: 1;
    }

    &-Link {
        color: inherit;
        display: flex;
        flex-direction: row;
        width: 100%;

        &:hover,
        &:focus {
            color: initial;
            text-decoration: none;
        }
    }

    &-ProductActions {
        position: absolute;
        inset-block-start: 19px;
        inset-inline-end: 15px;

        @include mobile {
            inset-block-start: 10px;
            inset-inline-end: 7px;
        }

        .ProductWishlistButton {
            .Button {
                background-color: #232320;
                border: 1px solid #232320;
                border-radius: 14px;
                padding: 13px 2px;

                @include mobile {
                    padding: 4px 4px;
                }

                svg {
                    width: 11px;
                    height: 12px;
                }
            }
        }
    }

    .TextPlaceholder,
    .Image_isPlaceholder {
        opacity: 0;
        animation: appear 100ms 300ms forwards;
    }

    &-Footer {
        align-items: center;
        display: flex;
        justify-content: space-between;

        > * {
            margin-inline-end: 5px;
        }

        .AddToCart_layout_grid {
            margin-inline-end: 0;
            height: auto;
            border-radius: 5px;
            font-size: 12px;
            color: var(--primary-base-color);

            span {
                font-size: 12px;
            }

            &:not([disabled]):hover {
                height: auto;
                border-radius: 5px;
                background-color: var(--primary-base-color);
                border-color: var(--primary-base-color);
                color: var(--color-white);
            }
        }
    }

    &-OutOfStock {
        align-items: center;
        display: flex;
        height: 48px;

        p {
            margin: 0;
        }
    }

    .Product-BrandnameLink {
        font-size: 12px;
    }

    &-ContentGrid {
        display: flex;
        border-bottom: 1px solid #DFDFDF;

        a,
        h4 {
            font-size: 12px;
            font-weight: 600;
            line-height: 18px;
            color: #515150;
            max-width: 100%;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        @include mobile {
            padding-inline: 12px;
        }

        @include mobile {
            a {
                font-size: 12px;
            }
        }
    }

    &-ContentAddtoCartWrapper {
        display: flex;
        align-items: center; 
        padding-inline: 16px;
        padding-block-start: 12px;

        @include mobile {
            padding-inline: 12px;
            padding-block-start: 12px;
            padding-block-end: 12px;
        }
    }

    &-StockStatus {
        padding: 8px;
        font-size: 12px;
        font-weight: 600;
        border-radius: 8px;
        white-space: nowrap;
        display: flex;
        align-items: center;

        &.IN_STOCK {
            background: #E9FFEF;
            color: #3C5F46;
            width: auto;
            height: 24px;
        }

        &.OUT_OF_STOCK {
            background-color: #F3B7B7;
            color: #CD0909;
            padding: 3px 8px 3px 8px;

            svg {
                margin-inline-end: 8px;
            }
        }

        @include mobile {
            font-size: 12px;
            padding: 3px 8px 3px 8px;
            white-space: nowrap;
            line-height: 18px;
        }
    }

    &_siblingsHaveBrands {
        .ProductCard-Brand {
            min-height: var(--product-card-brand-line-height);
        }
    }

    &_siblingsHaveTierPrice,
    &_siblingsHavePriceBadge {
        .ProductCard-PriceWrapper {
            min-height: 40px;
        }
    }

    &_siblingsHaveConfigurableOptions {
        .ProductCard-ConfigurableOptions {
            min-height: 20px;
        }
    }
}

.ProductCard_layout_list:has(.ProductCard-StockInfo) {
    padding: 0px;
}

.ProductCard-ConfigurableOptions:has(.ProductCard-Price) {
    .ProductCard-Qty {
        width: fit-content!important;
        margin: 16px 24px 18px 24px;
    }

    .Field-Wrapper_type_number {
        display: flex;
        justify-content: flex-end;
    }
}

.ProductCard-Content.ProductCard-Content_layout_list:has(.ProductCard-StockLabels) {
    .ProductCard-ProductListSku {
        border-bottom: 1px solid #F0EFEF;
        padding-block-start: 8%;
    }
}

.ProductCard-ContentMainBlock:has(.ProductCard-StockStatusHeading) {
    .ProductCard-ContentName {
        min-height: 210px;
    }
}

.ProductCard.ProductCard_layout_grid .ProductCard-PriceWrapper:not(:has(.ProductPrice-PriceBadge)) {
    @include desktop {
        .ProductPrice-PriceValue {
            padding-block-start: 14px;
        }
    }
}

